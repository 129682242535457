import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Button } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../blocks/layout/seo';
import { Firebase } from '../config/firebase';
import HomeLayout from '../blocks/layout/home';
import { AiOutlineTable } from 'react-icons/ai';
import { MdOutlineCodeOff } from 'react-icons/md';
import {
    VscCheckAll,
    VscCloudUpload,
    VscTable,
    VscLock,
} from 'react-icons/vsc';

export default function Home() {
    const [loading, setLoading] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    useEffect(() => {
        setLoading(true);
        async function asyncHandler(user) {
            if (user) {
                setIsLogged(true);
            }
        }
        Firebase.auth().onAuthStateChanged(asyncHandler);
    }, []);

    return (
        <>
            <SEO title="Form with Sheet — Google Sheet, Airtable, & Supabase" />
            <HomeLayout isLogged={isLogged}>
                <div className="hero-section">
                    <div className="hero-content">
                        <div className="left-content">
                            <h1>Forms data into Sheet</h1>
                            <p>
                                Collect your HTML form data directly into your
                                Google Spreadsheet, Airtable, or Supabase
                                without any coding.
                            </p>
                            <Link to="/signup">
                                <Button type="primary">
                                    Setup Form Backend — It's free
                                </Button>
                            </Link>

                            {/* &nbsp; &nbsp;
                            <Button icon={<PlayCircleOutlined />}>
                                Watch video
                            </Button> */}
                        </div>
                        <div className="right-content">
                            <div
                                id="demoVideo"
                                className="wistia_embed wistia_async_zyaqsplmrq popover=true popoverAnimateThumbnail=true"
                                style={{ maxWidth: 500, height: 278 }}
                            />
                        </div>
                    </div>
                </div>
                <div className="section-two">
                    <div className="features-list">
                        <div className="feature-card">
                            <div className="head">
                                <VscTable size={30} />
                                <div className="feature-name">
                                    Data Ownership
                                </div>
                            </div>
                            <p>
                                You will have full access & ownership to your
                                data into your Google Sheet, Airtable, or
                                Supabase.
                            </p>
                        </div>
                        <div className="feature-card">
                            <div className="head">
                                <MdOutlineCodeOff size={30} />
                                <div className="feature-name">No Backend</div>
                            </div>
                            <p>
                                You don't have to learn backend coding for your
                                form submission.
                            </p>
                        </div>
                        <div className="feature-card">
                            <div className="head">
                                <VscCloudUpload size={30} />
                                <div className="feature-name">File upload</div>
                            </div>
                            <p>
                                You can upload files via your form to the
                                sheets.
                            </p>
                        </div>
                        <div className="feature-card">
                            <div className="head">
                                <VscCheckAll size={30} />
                                <div className="feature-name">Super easy</div>
                            </div>
                            <p>
                                Just add form endpoint to your form as action
                                with POST method & rest we handle.
                            </p>
                        </div>
                        <div className="feature-card">
                            <div className="head">
                                <AiOutlineTable size={30} />
                                <div className="feature-name">Data view</div>
                            </div>
                            <p>
                                Better view of your sheet data into the
                                dashboard
                            </p>
                        </div>
                        <div className="feature-card">
                            <div className="head">
                                <VscLock size={30} />
                                <div className="feature-name">
                                    Domain Security
                                </div>
                            </div>
                            <p>
                                You can allow your form submission for
                                particular domains.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="section-three">
                    <div className="container">
                        <div className="home-section">
                            <h2>How does it work?</h2>
                        </div>
                        <div className="how-work">
                            <div className="flow-card">
                                <StaticImage
                                    src="../images/1.png"
                                    alt="Input values"
                                />
                                <h3>1 — Connect</h3>
                                <p>
                                    Select the Google Spreadsheet Where you
                                    wants to push form data.
                                </p>
                            </div>
                            <div className="flow-card">
                                <StaticImage
                                    src="../images/2.png"
                                    alt="Making"
                                />
                                <h3>2 — Code</h3>
                                <p>
                                    Copy the backend URL & place into Form
                                    action with method 'POST'
                                </p>
                            </div>
                            <div className="flow-card">
                                <StaticImage
                                    src="../images/3.png"
                                    alt="API Endpoint"
                                />
                                <h3>3 — Form responses</h3>
                                <p>
                                    Collect your form responses into your own
                                    database(Spreadsheet)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-seven">
                    <div className="container">
                        <div className="home-section">
                            <h2>
                                Get started in less than a minute — It's free
                            </h2>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Link to="/signup">
                                <Button type="primary">Get started now</Button>
                            </Link>
                            &nbsp; &nbsp;
                            <Button>
                                <Link to="/pricing">View pricing</Link>
                            </Button>
                        </div>
                        <br />
                    </div>
                </div>
            </HomeLayout>
        </>
    );
}
